import React from 'react';
import './style.css';

const WhatsAppButton = () => {
    return (
        <a
            href="https://wa.me/994774044030"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-button"
        >
            <picture>
                <source srcSet={require("assets/images/WhatsApp.webp")} type="image/webp"/>
                <img src={require("assets/images/WhatsApp.png")} alt="WhatsApp"/>
            </picture>
        </a>
    );
};

export default WhatsAppButton;
