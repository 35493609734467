import React from "react";
import {handleDownloadBtnClick} from "helpers";

const MobileNavigation = () => {
    const closeMenu = () => {
        const menu = document.getElementById("navbarToggleExternalContent");
        menu.classList.remove("show");
    };

    return (
        <>
            <nav className="navbar navigation-mobile d-flex d-lg-none">
                <ul className="navigation__bar">
                    <li className="col-5">
                        <div className="navigation__bar__logo">
                            <img
                                alt="logo"
                                className="pe-2"
                                src={require("assets/images/icons/logo.svg").default}
                            />
                        </div>
                    </li>
                    <li className="offset-1 col-4">
                        <div>
                            <button
                                className="btn btn-black"
                                onClick={handleDownloadBtnClick}
                            >
                                Yüklə
                            </button>
                        </div>
                    </li>
                    <li className="col-2">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarToggleExternalContent"
                            aria-controls="navbarToggleExternalContent"
                            aria-expanded="true"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </li>
                </ul>
            </nav>
            <div
                className="collapse navigation-mobile__bar"
                id="navbarToggleExternalContent"
            >
                <ul>
                    <li onClick={closeMenu}>
                        <a className="btn btn-empty" href="#about">
                            Haqqımızda
                        </a>
                    </li>
                    <li onClick={closeMenu}>
                        <a className="btn btn-empty" href="#functionality">
                            Funksionallıqlar
                        </a>
                    </li>
                    <li onClick={closeMenu}>
                        <a className="btn btn-empty" href="#price">
                            Qiymət paketi
                        </a>
                    </li>
                    <li onClick={closeMenu}>
                        <a className="btn btn-empty" href="/intro/privacy-policy">
                            Məxfilik siyasəti
                        </a>
                    </li>
                    <li onClick={closeMenu}>
                        <a className="btn btn-empty" href="/intro/terms">
                            İstifadə qaydaları
                        </a>
                    </li>
                    <hr/>
                    <li>
                        <a href="mailto:support@yonca.az" className="btn-empty text-decoration-none">
                            support@yonca.az
                        </a>
                    </li>
                    <li>
                        <a href="tel:+994774044030" style={{fontSize: '15px'}}
                           className="btn-empty text-decoration-none">
                            +994774044030
                        </a>
                    </li>
                    <li>
                        <p>
                            &copy; <span className="fw-bold"> DigiRella </span>
                        </p>
                    </li>
                </ul>
            </div>
        </>
    );
};

const DesktopNavigation = () => {
    return (
        <nav className="navigation row d-none d-lg-flex">
            <ul className="navigation__bar col-7">
                <li>
                    <div className="navigation__bar__logo">
                        <img
                            alt="logo"
                            className="pe-2"
                            src={require("assets/images/icons/logo.svg").default}
                        />
                    </div>
                </li>
                <li>
                    <a className="btn-empty" href="#about">
                        Haqqımızda
                    </a>
                </li>
                <li>
                    <a className="btn-empty" href="#functionality">
                        Funksionallıqlar
                    </a>
                </li>
                <li>
                    <a className="btn-empty" href="#price">
                        Qiymət paketi
                    </a>
                </li>
                <li>
                    <a rel="noopener noreferrer" target="_blank" className="btn-empty" href="https://blog.yonca.az/">
                        Bloq
                    </a>
                </li>
            </ul>
            <div className="offset-3 col-2">
                <button className="btn btn-black" onClick={handleDownloadBtnClick}>
                    Yüklə
                </button>
            </div>
        </nav>
    );
};

export const Menu = () => {
    return (
        <header className="menu container-lg g-0">
            <MobileNavigation/>
            <DesktopNavigation/>
        </header>
    );
};
