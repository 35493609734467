import React, {useState} from "react";

const FunctionalityBox = ({
                              label,
                              name,
                              openedFunctionName,
                              description,
                              iconUrl,
                              handleTitleClick,
                          }) => {
    const isDescriptionOpened = openedFunctionName === name;

    return (
        <div className="functionality__box">
            <div
                className={`functionality__box__icon ${
                    isDescriptionOpened ? "functionality__box__icon-inverse" : ""
                }`}
            >
                <img onClick={() => handleTitleClick(name)} alt="icon" src={iconUrl}/>
            </div>
            <div className="functionality__box__name">
                <p
                    onClick={() => handleTitleClick(name)}
                    className={`functionality__box__name__title ${
                        isDescriptionOpened ? "fw-bold fs-18" : "fw-500"
                    }`}
                >
                    {label}
                </p>
                {isDescriptionOpened && (
                    <p className="functionality__box__name__description functionality__box-open">{description}</p>
                )}
            </div>
        </div>
    );
};

const Functionality = () => {
    const functionalities = [
        {
            id: "news",
            imgUrl: require("assets/images/mainpage-mobile.png"),
            imgUrlSrcSet: require("assets/images/mainpage-mobile.webp")
        },
        {
            id: "points",
            imgUrl: require("assets/images/points-mobile.png"),
            imgUrlSrcSet: require("assets/images/points-mobile.webp")
        },
        {
            id: "planting",
            imgUrl: require("assets/images/planting-mobile.png"),
            imgUrlSrcSet: require("assets/images/planting-mobile.webp")
        },
        {
            id: "soil",
            imgUrl: require("assets/images/soil-mobile.png"),
            imgUrlSrcSet: require("assets/images/soil-mobile.webp")
        },
        {
            id: "subsidy",
            imgUrl: require("assets/images/subsidy-mobile.png"),
            imgUrlSrcSet: require("assets/images/subsidy-mobile.webp")
        },
        {
            id: "notification",
            imgUrl: require("assets/images/notification-mobile.png"),
        },
    ];

    const [openedFunctionality, setOpenedFunctionality] = useState(functionalities[0]);

    const setNewOpenedFunctionality = (id) => {
        const newOpenedFunctionality = functionalities.find((f) => f.id === id);
        setOpenedFunctionality(newOpenedFunctionality);
    };

    return (
        <section className="functionality container" id="functionality">
            <div className="col-sm-12 col-md-12 col-lg-4 mb-8">
                <p className="grey_background_title functionality__question">
                    <img
                        alt="leaf"
                        className="small_logo_txt"
                        src={require("assets/images/icons/logo-leaf_icon.svg").default}
                    />
                    Yoncanın üstünlükləri ?
                </p>
                <h2 className="functionality__title">Funksionallıqlar</h2>
            </div>
            <div className="row">
                <div className="col-lg-6 functionality__tools">
                    <FunctionalityBox
                        name="news"
                        label="Son dəqiqə xəbərləri və təlimlər"
                        iconUrl={
                            require("assets/images/icons/attention-green_icon.svg").default
                        }
                        description="Ucqar bölgələrdə yaşayan
            fermerlər də faydalı məlumatlar və 
            təlimlərdən daim xəbərdardır."
                        openedFunctionName={openedFunctionality.id}
                        handleTitleClick={(id) => setNewOpenedFunctionality(id)}
                    />
                    <FunctionalityBox
                        name="points"
                        label="Xidmət məntəqələri"
                        iconUrl={
                            require("assets/images/icons/location-green_icon.svg").default
                        }
                        openedFunctionName={openedFunctionality.id}
                        handleTitleClick={(id) => setNewOpenedFunctionality(id)}
                        description="Fermerə lazım olan məhsul satışı və xidmət məntəqələrinin siyahısı ilə fermer axtardığını daha tez tapır."
                    />
                    <FunctionalityBox
                        name="planting"
                        label="Əkin bəyan qeydiyyatı"
                        iconUrl={
                            require("assets/images/icons/leaf-green_icon.svg").default
                        }
                        openedFunctionName={openedFunctionality.id}
                        handleTitleClick={(id) => setNewOpenedFunctionality(id)}
                        description="Fermer əkin bəyanını rahatlıqla qeydə alır."
                    />
                    <FunctionalityBox
                        name="soil"
                        label="Torpaq sənədinin qeydiyyatı"
                        iconUrl={
                            require("assets/images/icons/check-green_icon.svg").default
                        }
                        openedFunctionName={openedFunctionality.id}
                        handleTitleClick={(id) => setNewOpenedFunctionality(id)}
                        description="Fermer sənədlərini telefonu ilə rahat qeydiyyatdan keçirdir və rəqəmsal mühitdə görür."
                    />
                    <FunctionalityBox
                        name="subsidy"
                        label="Subsidiya müraciətinin izlənməsi"
                        iconUrl={
                            require("assets/images/icons/clock-green_icon.svg").default
                        }
                        openedFunctionName={openedFunctionality.id}
                        handleTitleClick={(id) => setNewOpenedFunctionality(id)}
                        description="Fermer müraciətlə bağlı prosesləri izləyib, mövcud vəziyyətdən anında xəbərdar olur."
                    />
                    <FunctionalityBox
                        name="notification"
                        label="Fərdi bildirişlər"
                        iconUrl={
                            require("assets/images/icons/alert-green_icon.svg").default
                        }
                        openedFunctionName={openedFunctionality.id}
                        handleTitleClick={(id) => setNewOpenedFunctionality(id)}
                        description="Fermer təsərrüfatına özəl bildirişləri anında əldə edir."
                    />
                </div>
                <div className="d-none d-lg-flex offset-lg-1 col-lg-5 functionality__screens">
                    <img
                        className="arrow"
                        alt="arrow"
                        src={require("assets/images/icons/arrow.svg").default}
                    />
                    <picture>
                        <source srcSet={openedFunctionality.imgUrlSrcSet} type="image/webp"/>
                        <img className="functionality__screens__img" src={openedFunctionality.imgUrl}
                             alt="mobile-screen"/>
                    </picture>
                </div>
            </div>
        </section>
    );
};

export default Functionality;
