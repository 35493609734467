import React from "react";

const Footer = () => {
    return (
        <footer>
            <section className="container footer" id="contact">
                <ul>
                    <li className="col-12 col-lg-1 me-0">
                        <p className="mb-0 text-lg-start">
                            &copy;<span className="fw-bold">DigiRella</span>
                        </p>
                    </li>
                    <ul className="offset-lg-5 col-lg-6 d-none d-lg-flex">
                        <li>
                            <a className="btn-empty" href="/intro/privacy-policy">
                                Məxfilik siyasəti
                            </a>
                        </li>
                        <li>
                            <a className="btn-empty" href="/intro/terms">
                                İstifadə qaydaları
                            </a>
                        </li>
                        <li>
                            <a href="mailto:support@yonca.az" className="btn-empty">
                                support@yonca.az
                            </a>
                        </li>
                        <li>
                            <a href="tel:+994774044030" style={{fontSize: '15px'}} className="btn-empty">
                                +994774044030
                            </a>
                        </li>
                    </ul>
                </ul>
            </section>
        </footer>
    );
};

export default Footer;
